<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <inplay-left-bar-comp :pleague-list="leagueList" @changeTypeId="changeTypeId"
                                  @leagueChanged="leagueChanged"></inplay-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>실시간</sub-title>
                <div class="inplay">
                    <div class="left clearfix">
                        <div v-if="gameList == null || gameList.length <=0" style="text-align: center;font-weight: bold;font-size: 16px;color: #f4c600;margin-top: 20px">
                            현재 진행중인 경기가 없습니다
                        </div>
                        <div v-for="(item,index) in gameList" :key="index" v-if="item.isShow">
                            <inpaly-game-info
                                    :item="item"
                                    :active="currentGameId === item.apiId"
                                    @inplayDetail="getInplayGame(item)">
                            </inpaly-game-info>
                            <div class="mobile_inplay_detail" v-if="currentGameId === item.apiId">
                                <loading-inplay-comp v-if="isLoading"></loading-inplay-comp>
                                <div class="inplay_detail">
                                    <widget-inplay :pgame="inplay"
                                                   :home-name="homeName"
                                                   :away-name="awayName"></widget-inplay>
                                    <inplay-game-comp
                                            :inplay="game"
                                            :home-name="homeName"
                                            :away-name="awayName"></inplay-game-comp>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right clearfix" style="position: relative">
                        <loading-inplay-comp v-if="isLoading"></loading-inplay-comp>
                        <div class="inplay_detail">
                            <widget-inplay :pgame="inplay"
                                           :home-name="homeName"
                                           :away-name="awayName"></widget-inplay>
                            <inplay-game-comp
                                    :inplay="game"
                                    :home-name="homeName"
                                    :away-name="awayName"></inplay-game-comp>
                        </div>
                    </div>


                </div>
                <div class="inplay_wrap_2">

                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <inplay-bet-cart-comp  slot="betcart"></inplay-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
        <!--        <mobile-inplay-foot-menu-comp></mobile-inplay-foot-menu-comp>-->
        <bet-cart-icon-inplay-comp></bet-cart-icon-inplay-comp>
    </div>
</template>

<script>
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import {WEBSOCKET_URL} from '../../common/const'
    import InplayGameComp from "../../components/inplay/InplayGameComp";
    import {getSportsInplayGames} from "../../network/sportsInplayRequest";
    import SubTitle from "../../components/SubTitle";
    import InplayInfo from "../../components/inplay/child/InplayInfo";
    import store from "../../store";
    import {
        RECEIVE_HIDE_LOADING, RECEIVE_INPLAY_CART_ITEM, RECEIVE_INPLAY_CART_ITEM_DEL,
        RECEIVE_INPLAY_CART_ITEM_DEL_ALL,
        RECEIVE_SHOW_LOADING
    } from "../../store/mutation-types";
    import InplayBetCartComp from "../../components/inplay/InplayBetCartComp";
    import {mapGetters} from "vuex";
    import InpalyGameInfo from "../../components/inplay/InpalyGameInfo";
    import WidgetInplay from "../../components/inplay/child/WidgetInplay";
    import InplayLeftBarComp from "../../components/inplay/InplayLeftBarComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import MobileInplayFootMenuComp from "../../components/mobile/MobileInplayFootMenuComp";
    import LoadingComp from "../../components/LoadingComp";
    import LoadingInplayComp from "../../components/LoadingInplayComp";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import BetCartIconInplayComp from "../../components/BetCartIconInplayComp";


    export default {
        name: "SportsInplay",
        mixins:[postionMixin],
        components: {
            BetCartIconInplayComp,
            RightButtonsComp,
            UserInfoComp,
            LoadingInplayComp,
            LoadingComp,
            MobileInplayFootMenuComp,
            ScrollTopComp,
            InplayLeftBarComp,
            WidgetInplay,
            InpalyGameInfo,
            InplayBetCartComp,
            InplayInfo,
            SubTitle,
            InplayGameComp,
            FootComp,
            RightBarBannerComp,
            RightBarComp,
            SportsLeftBarComp,
            LeftBarComp,
            TopbarComp
        },
        computed: {
            ...mapGetters({
                'cartItems': 'getInplayCartItems',
                'rankConfig': 'getRankConfig',
                'bonusConfig': 'getBonusConfig',
            }),
        },
        data() {
            return {
                sportsConst,
                inplayConst,
                displayPosition: sportsConst.GAME_DISPLAYPOSITION_1x2,
                websocket: null,
                path: WEBSOCKET_URL,
                wsData: null,//websocket 에서 받아온 전체 데이터
                inplay: null,//FI별 상세 데이터
                game: null,
                websocketInterval: null,//websocket
                gameListInterval: null,
                currentGameId: 0, //현재 클릭한 실시간 경기 FI
                setTime: 1300, //websocket 에 메세지는 날리기는 주기
                gameList: null, //디비에서 추출한 현재 저장된 실시간경기,
                homeName: '',
                awayName: '',
                currentTypeId: 0,
                leagueList: [],
                checkedLeagueList: [],
                isLoading: false,
                lock: false,
                clickItem: null,
                position:"Inplay",
            }
        },
        methods: {
            changeTypeId(typeId) {
                //종목변경시 선택되였던 리그를 전부 초기화
                this.leagueList.forEach(league => {
                    league.checked = false
                })
                this.checkedLeagueList = []
                //currentTypeId 변경시 watch에서 자동으로 해당종목 경기를 세팅해줌
                this.currentTypeId = typeId
                this.leagueList.forEach(league => {
                    this.setLeagueIsShow(league)
                })
            },
            leagueChanged(league) {
                let index = this.checkedLeagueList.findIndex(l => {
                    return league.id === l.id
                })
                if (index === -1 && league.checked === true) {
                    this.checkedLeagueList.push(league)
                } else {
                    this.checkedLeagueList.splice(index, 1)
                }
            },
            getGameList() {
                getSportsInplayGames().then((res) => {
                    if (res.data.success) {
                        let resList = res.data.data
                        let newGameList = []
                        let nleagueList = []
                        let c = 0;
                        resList.forEach(game => {
                            c++;
                            //현재 선택된 경기유형에 한하여 보여주기 설정
                            this.setIsShow(game)

                            //리그가 존재하는지 판단하고 없으면 리그리스트에 추가
                            let index = this.leagueList.findIndex(league => {
                                return league.id === game.leagueId
                            })
                            //리그아이디설정
                            this.$set(game.league, 'id', game.leagueId)
                            //apiTypeId설정
                            this.$set(game.league, 'apiTypeId', game.apiTypeId)
                            if (index === -1) {
                                this.$set(game.league, 'checked', false)
                                this.setLeagueIsShow(game.league)
                                this.leagueList.push(game.league)
                            }
                            nleagueList.push(game.league)
                        })

                        /*let delLeagueIdx = []
                        this.leagueList.forEach(league => {
                          let index = nleagueList.findIndex(l => {
                            return l.id === league.id
                          })
                          if (index === -1) {
                            delLeagueIdx.push(index)
                          }
                        })*/
                        /* delLeagueIdx.forEach(idx => {
                           console.log('del league...', this.leagueList[idx])
                           this.leagueList.splice(idx, 1)
                         })*/

                        if (null == this.gameList || this.gameList.length === 0) {
                            this.gameList = resList;

                        } else {
                            resList.forEach((game) => {
                                let index = this.gameList.findIndex((oldGame) => {
                                    return game.apiId === oldGame.apiId
                                })
                                if (index === -1) {
                                    newGameList.push(game)
                                }
                            })
                            newGameList.forEach((ng) => {
                                this.gameList.push(ng)
                            })
                        }
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            resetGameList() {
                //스코어,상태 등 세팅, 존재하지 안는 경기 삭제
                let delKeys = []
                //this.gameList halfStatus 업데이트
                for (let i = 0; i < this.gameList.length; i++) {
                    let oldGame = this.gameList[i];
                    let key = "ID" + oldGame.apiId
                    let wsg = this.wsData[key]
                    if (wsg !== undefined) {
                        if (oldGame.apiId === wsg.id) {
                            try {

                                let ss = wsg.ss.split('-');
                                this.$set(this.gameList[i], 'homeScore', ss[0])
                                this.$set(this.gameList[i], 'awayScore', ss[1])
                            } catch (e) {
                            }
                            this.$set(this.gameList[i], 'halfStatus', wsg.halfStatus)
                            this.$set(this.gameList[i], 'timeMm', wsg.timeMm === undefined ? 0 : wsg.timeMm)
                            this.$set(this.gameList[i], 'timeSs', wsg.timeSs === undefined ? 0 : wsg.timeSs)
                            this.$set(this.gameList[i], 'homeImgId', wsg.homeImgId === undefined ? 0 : wsg.homeImgId)
                            this.$set(this.gameList[i], 'awayImgId', wsg.awayImgId === undefined ? 0 : wsg.awayImgId)
                            this.$set(this.gameList[i], 'gameCount', wsg.gameCount)

                        }
                    } else {
                        delKeys.push(i)
                    }

                }
                delKeys.forEach(i => {
                    this.gameList.splice(i, 1);
                })
            },
            getInplayGame(item) {
                this.lock = true
                this.isLoading = true
                if (null == this.websocket) {
                    this.init()
                }
                if (this.currentGameId === item.apiId) {
                    return false;
                } else {
                    this.game = null
                    this.inplay = null
                    this.currentGameId = item.apiId
                }
                this.homeName = item.homeTeamName
                this.awayName = item.awayTeamName
            },
            init() {
                if (typeof (WebSocket) === "undefined") {

                } else {
                    if (null == this.websocket) {
                        // 实例化socket
                        this.websocket = new WebSocket(this.path)
                        // 监听socket连接
                        this.websocket.onopen = this.open
                        // 监听socket错误信息
                        this.websocket.onerror = this.error
                        // 监听socket消息
                        this.websocket.onmessage = this.getMessage
                        this.websocket.onclose = this.close
                    }

                }
            },
            open() {
                this.inplay = null
                if (null != this.websocket) {
                    this.send()
                }
                this.websocketInterval = setInterval(() => {
                    if (null != this.websocket) {
                        this.send()
                    } else {
                        this.init()
                    }
                }, this.setTime)
            },
            error(e) {
                console.log("ERROR!")
                console.log(e)
                this.inplay = null
                this.currentGameId = 0
                this.wsData = null
            },
            getMessage(message) {
                if (message.data !== 'emptymap') {
                    this.wsData = JSON.parse(message.data)
                    this.resetGameList()
                    let key = "ID" + this.currentGameId
                    if (!this.lock) {
                        this.inplay = this.wsData[key] === undefined ? null : this.wsData[key]
                    }
                    this.watchingInplay()
                } else {
                    this.gameList = null
                    this.inplay = null
                }
            },
            send: function (message) {
                try {
                    let obj = {}
                    obj.fi = this.currentGameId
                    this.websocket.send(JSON.stringify(obj))

                } catch (e) {
                    console.log(e)
                }

            },
            close() {
                this.socket = null
                this.inplay = null
                this.currentGameId = 0
                if (this.websocketInterval != null) {
                    this.websocket = null
                    clearInterval(this.websocketInterval)
                    this.websocketInterval = null
                }
            },
            matchingGames(currentInplay) {

                let delKeys = []
                for (let key in this.game.oddsMaps) {
                    //this.game oddsMaps 에서 currentInplay oddsMaps에 없는 경기를 key를 저장
                    if (currentInplay.oddsMaps[key] === undefined) {
                        delKeys.push(key)
                    }
                    this.game.oddsMaps[key].forEach((g) => {
                        this.$set(g, 'homeOddsUp', false)
                        this.$set(g, 'homeOddsDown', false)
                        this.$set(g, 'awayOddsUp', false)
                        this.$set(g, 'awayOddsDown', false)
                        this.$set(g, 'drawOddsUp', false)
                        this.$set(g, 'drawOddsDown', false)

                        //배팅카드에 존재하는 내역을 acive
                        try {

                            let index = this.$store.state.inplayCartItems.findIndex(cartItem => {
                                return cartItem.commonId === g.commonId
                            })
                            if (index !== -1) {
                                g.selectWay = this.$store.state.inplayCartItems[index].selectWay
                                g.selectedId = this.$store.state.inplayCartItems[index].selectId
                                g.selectOdd = this.$store.state.inplayCartItems[index].selectOdd
                            }

                        } catch (e) {
                            console.log(e)
                        }
                    })

                }
                //현재 경기에 없는 oddsMaps key를 삭제
                delKeys.map((dk) => {
                    this.$delete(this.game.oddsMaps, dk)
                })

                //1.존재하지 않는 경기유형 추가 ex: ID100
                let currentKeys = Object.keys(currentInplay.oddsMaps)
                for (let key in currentInplay.oddsMaps) {

                    if (this.game.oddsMaps[key] == undefined) {
                        this.$set(this.game.oddsMaps, key, currentInplay.oddsMaps[key])
                    }
                }

                //위에서까지 현재 this.gamem oddsMaps 경기유형 내역 하고 currentInplay oddsMaps 가 동일하게 설정되엿슴
                //아래부터 oddsMaps 안에 각개 유형의 경기 내용을 체크
                //this.game.oddsMaps[key] 에서 없는 경기 삭제
                for (let key in this.game.oddsMaps) {
                    //this.game oddsMaps 에서 currentInplay oddsMaps에 없는 경기를 key를 저장
                    let currentGames = currentInplay.oddsMaps[key];
                    let oldGames = this.game.oddsMaps[key]
                    let noneGameIndex = []
                    oldGames.forEach((og, idx) => {
                        let currentIndex = currentGames.findIndex((cg) => {
                            return og.commonId === cg.commonId
                        })
                        if (currentIndex === -1) {
                            noneGameIndex.push(idx)
                        }
                    })
                    //삭제
                    noneGameIndex.forEach(i => {
                        this.game.oddsMaps[key].splice(i, 1)
                    })
                }
                //currentInplay에 존재하지만 this.game에 없는 경기를 추가
                for (let key in currentInplay.oddsMaps) {
                    let currentGames = currentInplay.oddsMaps[key];
                    let oldGames = this.game.oddsMaps[key]
                    let noneGameIndex = []
                    currentGames.forEach((cg, idx) => {
                        let oldIndex = oldGames.findIndex((og => {
                            return og.commonId === cg.commonId
                        }))
                        if (oldIndex === -1) {
                            noneGameIndex.push(idx)
                        } else {

                            //동일경기일경우  배당비교 및 업데이트
                            try {
                                let oldhomeOdds = this.game.oddsMaps[key][idx].homeOdds;
                                let currenthomeOdds = currentGames[idx].homeOdds;
                                if (oldhomeOdds !== undefined && currenthomeOdds !== undefined) {
                                    if (parseFloat(currenthomeOdds) > parseFloat(oldhomeOdds)) {
                                        this.$set(this.game.oddsMaps[key][oldIndex], 'homeOddsUp', true)
                                    }
                                    if (parseFloat(currenthomeOdds) < parseFloat(oldhomeOdds)) {
                                        this.$set(this.game.oddsMaps[key][oldIndex], 'homeOddsDown', true)
                                    }
                                }
                            } catch (e) {

                            }

                            try {
                                let oldawayOdds = this.game.oddsMaps[key][idx].awayOdds;
                                let currentawayOdds = currentGames[idx].awayOdds;
                                if (oldawayOdds !== undefined && currentawayOdds !== undefined) {
                                    if (parseFloat(currentawayOdds) > parseFloat(oldawayOdds)) {
                                        this.$set(this.game.oddsMaps[key][oldIndex], 'awayOddsUp', true)
                                    }
                                    if (parseFloat(currentawayOdds) < parseFloat(oldawayOdds)) {
                                        this.$set(this.game.oddsMaps[key][oldIndex], 'awayOddsDown', true)
                                    }
                                }
                            } catch (e) {

                            }

                            try {
                                let olddrawOdds = this.game.oddsMaps[key][idx].drawOdds;
                                let currentdrawOdds = currentGames[idx].drawOdds;
                                if (olddrawOdds !== undefined && currentdrawOdds !== undefined) {
                                    if (parseFloat(currentdrawOdds) > parseFloat(olddrawOdds)) {
                                        this.$set(this.game.oddsMaps[key][oldIndex], 'drawOddsUp', true)
                                    }
                                    if (parseFloat(currentdrawOdds) < parseFloat(olddrawOdds)) {
                                        this.$set(this.game.oddsMaps[key][oldIndex], 'drawOddsDown', true)
                                    }
                                }
                            } catch (e) {
                            }
                            //홈배당설정
                            try {
                                this.game.oddsMaps[key][idx].homeOdds = currentGames[idx].homeOdds
                                this.game.oddsMaps[key][idx].homeSu = currentGames[idx].homeSu
                            } catch (e) {

                            }
                            //원정배당설정
                            try {
                                this.game.oddsMaps[key][idx].awayOdds = currentGames[idx].awayOdds
                                this.game.oddsMaps[key][idx].awaySu = currentGames[idx].awaySu

                            } catch (e) {

                            }
                            //무배당설정
                            try {
                                this.game.oddsMaps[key][idx].drawOdds = currentGames[idx].drawOdds
                                this.game.oddsMaps[key][idx].drawSu = currentGames[idx].drawSu
                            } catch (e) {

                            }
                            //오버언더기준치설정
                            try {
                                this.game.oddsMaps[key][idx].refVal = currentGames[idx].refVal
                            } catch (e) {

                            }


                        }
                    })
                    noneGameIndex.forEach(i => {
                        this.game.oddsMaps[key].push(currentGames[i])
                    })

                }
                this.isLoading = false
                this.lock = false

            },
            setGame(game, way, sid) {//선택팀 및 선택된 배당 설정
                this.$set(game, 'selectWay', way)
                if (way === sportsConst.WAY_NONE) {
                    this.$set(game, 'selectOdd', 1)
                    this.$set(game, 'selectId', null)
                    this.$set(game, 'selectText', '')
                    this.$set(game, 'hscoreText', '')
                }
                if (way === sportsConst.WAY_HOME) {
                    this.$set(game, 'selectOdd', game.homeOdds)
                    this.$set(game, 'selectId', sid)
                    this.$set(game, 'selectText', '')
                    this.$set(game, 'hscoreText', game.hscoreText)
                }
                if (way === sportsConst.WAY_DRAW) {
                    this.$set(game, 'selectOdd', game.drawOdds)
                    this.$set(game, 'selectId', sid)
                    this.$set(game, 'selectText', '')
                    this.$set(game, 'dscoreText', game.dscoreText)
                }
                if (way === sportsConst.WAY_AWAY) {
                    this.$set(game, 'selectOdd', game.awayOdds)
                    this.$set(game, 'selectId', sid)
                    this.$set(game, 'selectText', '')
                    this.$set(game, 'ascoreText', game.ascoreText)
                }
            },
            setIsShow(game) {
                if (this.currentTypeId === 0) {
                    game.isShow = true
                } else {
                    if (game.apiTypeId === this.currentTypeId) {
                        game.isShow = true
                    } else {
                        game.isShow = false
                    }
                }
            },
            setLeagueIsShow(league) {
                if (this.currentTypeId === 0) {
                    this.$set(league, 'isShow', true)
                } else {
                    if (league.apiTypeId === this.currentTypeId) {
                        this.$set(league, 'isShow', true)
                    } else {
                        this.$set(league, 'isShow', false)
                    }
                }
            },
            watchingInplay() {
                /* console.log(this.inplay)*/
                //경기가 없을경우
                if (this.inplay === null || this.inplay === undefined || this.inplay.timeStatus === inplayConst.TIME_STATUS_MATCH_END) {
                    this.game = null
                    this.lock = false
                } else {
                    //FI가다를경우 this.game 초기화
                    if (this.game != null && this.game.id !== this.inplay.id) {
                        this.game = null;
                    }
                    for (let o in this.inplay.oddsMaps) {
                        this.inplay.oddsMaps[o].forEach(g => {
                            this.$set(g, 'homeOddsUp', false)
                            this.$set(g, 'homeOddsDown', false)
                            this.$set(g, 'awayOddsUp', false)
                            this.$set(g, 'awayOddsDown', false)
                            this.$set(g, 'drawOddsUp', false)
                            this.$set(g, 'drawOddsDown', false)
                            this.$set(g, 'commonId', g.homeId + '' + g.awayId)
                            this.$set(g, 'selectWay', null)
                            this.$set(g, 'selectId', null)
                            this.$set(g, 'selectOdd', 1)
                            this.$set(g, 'hscoreText', '') //스코어 경기명칭
                            this.$set(g, 'dscoreText', '')
                            this.$set(g, 'ascoreText', '')
                            this.$set(g, 'homeName', this.homeName)
                            this.$set(g, 'awayName', this.awayName)
                            this.$set(g, 'fi', this.inplay.id)
                            try {
                                if (g.homeOdds !== undefined) {
                                    g.homeOdds = parseFloat(g.homeOdds).toFixed(2)
                                }
                            } catch (e) {

                            }
                            try {
                                if (g.awayOdds !== undefined) {
                                    g.awayOdds = parseFloat(g.awayOdds).toFixed(2)
                                }
                            } catch (e) {

                            }
                            try {
                                if (g.drawOdds !== undefined) {
                                    g.drawOdds = parseFloat(g.drawOdds).toFixed(2)
                                }
                            } catch (e) {

                            }

                        })
                    }

                    if (this.game == null) {
                        this.game = JSON.parse(JSON.stringify(this.inplay))
                        this.isLoading = false
                        this.lock = false
                    } else {
                        this.matchingGames(this.inplay)
                    }
                }
            }
        },
        beforeDestroy() {
            if(null != this.gameListInterval){
                clearInterval(this.gameListInterval)
            }
            if(null != this.websocketInterval){
                clearInterval(this.websocketInterval)
            }
            if (this.websocket != null) {
                this.websocket.close()
                this.websocket = null
            }
            this.$store.commit(RECEIVE_INPLAY_CART_ITEM_DEL_ALL)
        },
        created() {
            this.init()
            this.$store.commit(RECEIVE_SHOW_LOADING);
            //랭킹,보너스,베팅금액,당첨금액 등 설정요청
            this.$store.dispatch('actionCommonConfig')
            // 初始化
            this.getGameList();
            if (null == this.gameListInterval) {
                this.gameListInterval = setInterval(() => {
                    this.getGameList();
                }, 10000)
            }


            //베팅부분
            //베팅카드 추가
            this.$bus.$off('addInplayGame2BetCart')
            this.$bus.$on('addInplayGame2BetCart', (val) => {
                if (null != this.game) {
                    //클릭한경기
                    let selectedGame = val.game
                    /*console.log(val)*/
                    //선택사항
                    let way = val.way
                    let text = val.text
                    let sid = val.selectId
                    if (this.cartItems.length > 0) {
                        let index = this.cartItems.findIndex((item) => {
                            return item.fi === selectedGame.fi
                        })
                        //1.카트에 담겨져 있고 선택사항이 같거나 삭제
                        if (index !== -1) {
                            this.$store.commit(RECEIVE_INPLAY_CART_ITEM_DEL, index)
                        }
                    }

                    let cartItem = {}
                    for (let key in this.game.oddsMaps) {
                        //this.game oddsMaps 에서 currentInplay oddsMaps에 없는 경기를 key를 저장
                        this.game.oddsMaps[key].forEach((g) => {
                            let oddTypeArr = g.oddTypeText.split(",");
                            if (g.commonId === selectedGame.commonId) {
                                this.setGame(g, way, sid)
                                cartItem = {
                                    fi: g.fi,
                                    selectOdd: g.selectOdd,
                                    selectId: g.selectId,
                                    hscoreText: g.hscoreText,
                                    dscoreText: g.dscoreText,
                                    ascoreText: g.ascoreText,
                                    commonId: g.commonId,
                                    selectWay: g.selectWay,
                                    homeName: this.homeName,
                                    awayName: this.awayName,
                                    oddType: g.oddType,
                                    typeText: oddTypeArr[0],
                                    sectionText: oddTypeArr[1],
                                    kindText: oddTypeArr[2],
                                    kind: oddTypeArr[3],
                                    refVal: g.refVal,
                                    selectText: text,
                                }

                            } else {
                                this.setGame(g, sportsConst.WAY_NONE)
                            }
                        })
                    }
                    this.$store.commit(RECEIVE_INPLAY_CART_ITEM, cartItem)
                }
            })

            //베팅카드에서 삭제
            this.$bus.$off('deleteInplayCartItem')
            this.$bus.$on('deleteInplayCartItem', (val) => {
                if (this.cartItems.length > 0) {
                    let delCartItem = val
                    let index = this.cartItems.findIndex((item) => {
                        return item.fi === delCartItem.fi && item.commonId === delCartItem.commonId
                    })
                    if (index !== -1) {
                        this.$store.commit(RECEIVE_INPLAY_CART_ITEM_DEL, index)
                    }
                    if (this.game.id === delCartItem.fi) {
                        for (let key in this.game.oddsMaps) {
                            this.game.oddsMaps[key].forEach((g) => {
                                if (g.commonId === delCartItem.commonId) {
                                    this.setGame(g, sportsConst.WAY_NONE)
                                }
                            })
                        }
                    }
                }
            })

            //베팅카드 초기화 ,카트아이템 전체 삭제 deleteInplayAllCartItem
            this.$bus.$off('deleteInplayAllCartItem')
            this.$bus.$on('deleteInplayAllCartItem', () => {
                for (let key in this.game.oddsMaps) {
                    this.game.oddsMaps[key].forEach((g) => {
                        this.setGame(g, sportsConst.WAY_NONE)
                    })
                }
                this.$store.commit(RECEIVE_INPLAY_CART_ITEM_DEL_ALL)
            })


        },
        watch: {
            inplay: {
                handler() {
                    /*/!* console.log(this.inplay)*!/
                    //경기가 없을경우
                    if (this.inplay === null || this.inplay === undefined || this.inplay.timeStatus === inplayConst.TIME_STATUS_MATCH_END) {
                      this.game = null
                      this.lock = false
                    } else {
                      //FI가다를경우 this.game 초기화
                      if (this.game != null && this.game.id !== this.inplay.id) {
                        this.game = null;
                      }


                      for (let o in this.inplay.oddsMaps) {
                        this.inplay.oddsMaps[o].forEach(g => {
                          this.$set(g, 'homeOddsUp', false)
                          this.$set(g, 'homeOddsDown', false)
                          this.$set(g, 'awayOddsUp', false)
                          this.$set(g, 'awayOddsDown', false)
                          this.$set(g, 'drawOddsUp', false)
                          this.$set(g, 'drawOddsDown', false)
                          this.$set(g, 'commonId', g.homeId + '' + g.awayId)
                          this.$set(g, 'selectWay', null)
                          this.$set(g, 'selectId', null)
                          this.$set(g, 'selectOdd', 1)
                          this.$set(g, 'hscoreText', '') //스코어 경기명칭
                          this.$set(g, 'dscoreText', '')
                          this.$set(g, 'ascoreText', '')
                          this.$set(g, 'homeName', this.homeName)
                          this.$set(g, 'awayName', this.awayName)
                          this.$set(g, 'fi', this.inplay.id)
                          try {
                            if (g.homeOdds !== undefined) {
                              g.homeOdds = parseFloat(g.homeOdds).toFixed(2)
                            }
                          } catch (e) {

                          }
                          try {
                            if (g.awayOdds !== undefined) {
                              g.awayOdds = parseFloat(g.awayOdds).toFixed(2)
                            }
                          } catch (e) {

                          }
                          try {
                            if (g.drawOdds !== undefined) {
                              g.drawOdds = parseFloat(g.drawOdds).toFixed(2)
                            }
                          } catch (e) {

                          }

                        })
                      }

                      if (this.game == null) {
                        this.game = JSON.parse(JSON.stringify(this.inplay))
                        this.isLoading = false
                        this.lock = false
                      } else {
                        this.matchingGames(this.inplay)
                      }
                    }*/
                },
                immediate: true,
                deep: false
            },
            currentTypeId() {
                this.gameList.forEach(game => {
                    this.setIsShow(game)
                })
            },
            checkedLeagueList() {
                if (this.checkedLeagueList.length === 0) {
                    this.gameList.forEach(game => {
                        this.setIsShow(game)
                    })
                } else {
                    let ids = []
                    this.checkedLeagueList.forEach(league => {
                        ids.push(league.id)
                    })
                    this.gameList.forEach(game => {
                        game.isShow = ids.indexOf(game.leagueId) !== -1;
                    })

                }

            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
</style>

