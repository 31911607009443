<template>

    <!--베팅카트-->
    <div class="bet_cart">
        <div class="bet_cart_timer">
            <clock></clock>
        </div>
        <div class="cart_head">
                        <div class="title">BETTING CART</div>
<!--            <div class="title-img">-->
<!--                <img src="../../assets/images/right/cart/btn-betcart.png" alt="" style="width: 100%">-->
<!--            </div>-->
        </div>
        <div class="cart">
            <div class="cart_selected_items" v-for="(item,index) in $store.state.inplayCartItems" :key="item.id">
                <div class="item_inplay" :key="item.id">
                    <div class="tn">{{item.homeName}} vs {{item.awayName}}</div>
                    <div class="type">{{item.typeText}}, {{item.sectionText}}, {{item.kindText}}</div>
                    <div class="sel">
                                <span class="st">
                                    {{item.selectText}}
                                </span>
                        <span class="bd">
                                    {{item.selectOdd}}
                                </span>
                        <span class="del" @click="del(item)">
                                    <i class="fa fa-close"></i>
                                </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$store.state.inplayCartItems.length === 0" class="cart_selected_items">
            <div class="item">경기를 선택하세요</div>
        </div>
        <div class="info">
            <div class="item">
                <div>
                    <span class="t1">최대베팅</span><span
                        class="t2">{{this.rankConfig.inplayBetMaxLimitCash|comma}} 원</span>
                </div>
                <div>
                    <span class="t1">최대적중</span><span
                        class="t2">{{this.rankConfig.inplayWinMaxLimitCash|comma}} 원</span>
                </div>
                <div>
                    <span class="t1">보유머니</span><span class="t2">{{$store.state.userInfo.cash|comma}} 원</span>
                </div>
                <div v-if="bonusOdds > 0" class="bg-color-gray it">
                    <span class="t1 text-white">보너스 배당</span><span class="t2 text-white">{{bonusOdds}}</span>
                </div>
                <div v-if="bonusCash > 0" class="bg-color-gray it">
                    <span class="t1 text-white">보너스 금액</span><span class="t2 text-white">{{bonusCash|comma}}</span>
                </div>
                <div>
                    <span class="t1 text-bet-infos">배당</span><span class="t2 text-bet-infos">{{totalOdd}}</span>
                </div>
                <div>
                    <span class="t1 text-bet-infos">예상당첨금액</span><span class="t2 text-bet-infos">{{totalWinCash|comma}}</span>
                </div>
                <div>
                    <span class="t1 text-bet-infos">베팅금액</span>
                    <span class="t2">
                                <input type="text" v-model="betCash" @input="setBetCahsComma" style="width: 100px;"/>
                            </span>
                </div>
            </div>
            <div class="money_buttons">
                <button class="" @click="cashAdd(5000)">5천</button>
                <button class=" " @click="cashAdd(10000)">1만</button>
                <button class="" @click="cashAdd(50000)">5만</button>
                <button class="" @click="cashAdd(100000)">10만</button>
                <button class="" @click="cashAdd(100000)">20만</button>
                <button class=" " @click="cashAdd(500000)">50만</button>
                <button class=" " @click="cashAdd(1000000)">100만</button>
                <button class=" " @click="maxCash()">MAX</button>
<!--                <button class=" " @click="allInCash()">올인</button>-->
                <button class=" " @click="resetCash()">금액초기화</button>
<!--                <button class=" " @click="initBetCart()">초기화</button>-->
            </div>
            <div class="btn_bet">
                <button @click="betNow()">베팅하기</button>
<!--                <a href="javascript:void(0)" @click="betNow()">-->
<!--                    <img src="../../assets/images/right/cart/btn-betnow.png" style="width: 100%" alt="">-->
<!--                </a>-->
            </div>
        </div>
    </div>


</template>

<script>
    import {
        RECEIVE_HIDE_LOADING, RECEIVE_HIDE_LOADING_INPLAY,
        RECEIVE_INPLAY_CART_ITEM_DEL,
        RECEIVE_INPLAY_CART_ITEM_DEL_ALL,
        RECEIVE_SHOW_LOADING,
        RECEIVE_SHOW_LOADING_INPLAY,
    } from "../../store/mutation-types";
    import sportsConst from "../../common/sportsConst";
    import {mapGetters} from 'vuex'
    import {dobet, doinplaybet} from "../../network/sportsBetRequest";
    import Clock from "../Clock";
    import {checkLoginMinxin} from "../../common/mixin";

    export default {
        name: "InplayBetCartComp",
        mixins: [checkLoginMinxin],
        components: {Clock},
        data() {
            return {
                sportsConst,
                odds: 0,//선택경기 총배당
                winCash: 0,//총당첨금액
                betCash: '',//베팅금액
                betInfo: {
                    selectedVals: [],
                    betCash: 0
                },
                bonusCash: 0,//보너스 금액
                bonusOdds: 0,//보너스 배당,
                mobileBetCartClose: false,
                locked: false
            }
        },
        methods: {
            /*mobile 페이지 배팅카드 보여줄시 닫기*/
            closeMobileCart() {
                //RightBarSportsComp.vue에서 모니터
                this.$bus.$emit('rightBarHandle', false);
            },
            /*배팅카트 아이템 삭제*/
            del(item) {
                this.$bus.$emit('deleteInplayCartItem', item);
            },
            /*베팅카드 머니 버튼클릭*/
            cashAdd(amount) {
                if(this.betCash == ''){
                    this.betCash = 0
                }
                this.betCash = this.$root.$options.filters.comma(this.getBetCash + parseInt(amount))
            },
            setBetCahsComma(){
                this.betCash = this.$root.$options.filters.comma(this.betCash)
            },
            resetCash() {
                this.betCash = '';
            },
            allInCash() {
                this.betCash = this.$root.$options.filters.comma(this.$store.state.userInfo.cash)
            },
            /*다폴더 보너스*/
            initBonus() {
                /*보너스*/
                this.bonusCash = 0;
                this.bonusOdds = 0;
                let folderCount = this.cartItems.length;
                let bonusIntotal = 0;
                this.cartItems.map(function (item) {
                    if (item.selectOdd < sportsConst.BONUS_BEDANG_EXCEPT_FLAG) {
                        folderCount--;
                    }
                })
                switch (folderCount) {
                    case 0:
                    case 1:
                        this.bonusOdds = 0;
                        break
                    case 2:
                        if (this.bonusConfig.f2 > 1) {
                            this.bonusOdds = this.bonusConfig.f2;
                        }
                        break;
                    case 3:
                        if (this.bonusConfig.f3 > 1) {
                            this.bonusOdds = this.bonusConfig.f3;
                        }
                        break;
                    case 4:
                        if (this.bonusConfig.f4 > 1) {
                            this.bonusOdds = this.bonusConfig.f4;
                        }
                        break;
                    case 5:
                        if (this.bonusConfig.f5 > 1) {
                            this.bonusOdds = this.bonusConfig.f5;
                        }
                        break;
                    case 6:
                        if (this.bonusConfig.f6 > 1) {
                            this.bonusOdds = this.bonusConfig.f6;
                        }
                        break;
                    case 7:
                        if (this.bonusConfig.f7 > 1) {
                            this.bonusOdds = this.bonusConfig.f7;
                        }
                        break;
                    case 8:
                        if (this.bonusConfig.f8 > 1) {
                            this.bonusOdds = this.bonusConfig.f8;
                        }
                        break;
                    case 9:
                        if (this.bonusConfig.f9 > 1) {
                            this.bonusOdds = this.bonusConfig.f9;
                        }
                        break;
                    default:
                        if (this.bonusConfig.f10 > 1) {
                            this.bonusOdds = this.bonusConfig.f10;
                        }
                        break;
                }
                bonusIntotal = Math.round(this.getBetCash * (this.odds * this.bonusOdds).toFixed(2));
                this.bonusCash = bonusIntotal - this.totalWinCash;
            },
            /*베팅카드 초기화 ,아이템 전체 삭제*/
            initBetCart() {
                this.betCash = ''
                this.$bus.$emit('deleteInplayAllCartItem');
            },
            /*상한가 베팅*/
            maxCash() {
                if (this.odds <= 1 || this.cartItems.length === 0) return false;
                this.betCash = this.$root.$options.filters.comma(Math.floor((this.rankConfig.inplayWinMaxLimitCash - 6000) / this.odds))
                if(this.getBetCash > this.rankConfig.inplayBetMaxLimitCash){
                    this.betCash = this.$root.$options.filters.comma(this.rankConfig.inplayBetMaxLimitCash)
                }
            },
            /*베팅하기*/
            betNow() {
                if (!this.checkLogin()) {
                    return false
                }
                //선택경기개수 체크
                if (this.cartItems.length === 0) {
                    this.$swal({
                        title: '베팅하실 경기를 선택하세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                //단폴더 체크
                if (this.cartItems.length === 1) {
                    if (this.getBetCash > this.rankConfig.inplayBetOneMaxLimitCash) {

                        this.$swal({
                            title: '단폴더 최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.inplayBetOneMaxLimitCash) + ' 원을 초과하였습니다',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return false;
                    }
                }
                //두폴더 체크
                if (this.cartItems.length === 2) {
                    if (this.getBetCash > this.rankConfig.inplayBetTwoMaxLimitCash) {
                        this.$swal({
                            title: '두폴더 최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.inplayBetTwoMaxLimitCash) + ' 원을 초과하였습니다',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return false;
                    }
                }

                //최고베당체크
                if (this.odds > this.rankConfig.inplayOddsMaxLimit) {
                    this.$swal({
                        title: '최대 배당율 ' + this.$root.$options.filters.comma(this.rankConfig.inplayOddsMaxLimit) + ' 배를 초과하였습니다',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최소베팅금액
                if (this.getBetCash < this.rankConfig.inplayBetMinLimitCash) {
                    this.$swal({
                        title: '최소 베팅금액은 ' + this.$root.$options.filters.comma(this.rankConfig.inplayBetMinLimitCash) + ' 원입니다',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최대베팅금액
                if (this.getBetCash > this.rankConfig.inplayBetMaxLimitCash) {
                    this.$swal({
                        title: '최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.inplayBetMaxLimitCash) + ' 원을 초과하였습니다',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최대당첨금액
                if (this.totalWinCash > this.rankConfig.inplayWinMaxLimitCash) {
                    this.$swal({
                        title: '최대 당첨금액 ' + this.$root.$options.filters.comma(this.rankConfig.inplayWinMaxLimitCash) + ' 원을 초과하였습니다',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                let betcontent = this.$root.$options.filters.comma(this.getBetCash) + '을 베팅하시겠습니까?';
                if (this.cartItems.length === 1 && this.$store.state.userInfo.rank.betOneWincashPercent > 0) {
                    betcontent = '단폴더 베팅 시 -' + this.$store.state.userInfo.rank.betOneWincashPercent + ' 차감됩니다. 베팅하시겠습니까?';
                }
                //베팅시작
                this.$swal({
                    title: betcontent,
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.cartItems.forEach((item) => {
                            let txt = item.homeName + ' vs ' + item.awayName + ', ' + item.selectText
                            let refVal = item.refVal ? item.refVal : 1000;
                            let val = item.fi + '@' + item.oddType + '@' + item.selectId + '@' + item.selectWay + '@' + txt + '@' + item.selectOdd + '@' + refVal

                            this.betInfo.selectedVals.push(val);
                        })
                        this.betInfo.betCash = this.getBetCash;
                        this.$store.commit(RECEIVE_SHOW_LOADING_INPLAY)
                        doinplaybet(this.betInfo).then(res => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '베팅성공',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.$store.commit(RECEIVE_INPLAY_CART_ITEM_DEL_ALL)
                                //베팅성공시 회원정보를 init
                                this.$store.dispatch('actionUserInfo')
                                //TODO 랭킹에서 최소베팅금액변경 변경
                                this.betCash = '';
                                this.$bus.$emit('deleteInplayAllCartItem');
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.betCash = ''
                            }
                            this.$store.commit(RECEIVE_HIDE_LOADING_INPLAY)
                        })
                    }
                })

            },

            hideCart() {
                //모바일경우 베팅카드 숨김기능
                //RightBarComp.vue에서 모니터
                this.$bus.$emit('rightMenueHandle', false)
            },
            cartLockAble() {
                this.locked = !this.locked;
                //카트고정여부
                this.$bus.$emit('cartLockAble', this.locked)
            }

        },
        computed: {
            ...mapGetters({
                'cartItems': 'getInplayCartItems',
                'rankConfig': 'getRankConfig',
                'bonusConfig': 'getBonusConfig'
            }),
            /*총배당*/
            totalOdd() {
                return this.odds.toFixed(2)
            },
            /*총당첨*/
            totalWinCash() {
                if(this.betCash != ''){
                    return Math.round(this.totalOdd * this.getBetCash)
                } else {
                    return 0
                }
            },
            /*캐쉬가 String으로 되여 전부 Integer로 전화*/
            getBetCash() {
                if(this.betCash == '') return 0
                return parseInt(this.$root.$options.filters.replace(this.betCash, ',', ''))
            }
        },
        created() {
            this.betCash = ''
        },
        watch: {
            /*카드아이템이 변경될때마다 체크*/
            "cartItems": {
                deep: true,
                handler: function (newValue) {
                    /*총배당을 0으로 설정*/
                    this.odds = 0;
                    newValue.forEach((item) => {
                        if (this.odds === 0) {
                            this.odds = parseFloat(item.selectOdd)
                        } else {
                            this.odds = this.odds * parseFloat(item.selectOdd)
                        }
                    })
                    this.initBonus()
                }
            },
            betCash() {
                this.initBonus()
            },


        },
    }
</script>

<style scoped>


</style>